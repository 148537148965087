import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import jesus from '../../images/jesus.svg';
import cloudLeft from '../../images/cloud-left.svg';
import cloudRight from '../../images/cloud-right.svg';
import bigCrosses from '../../images/big-crosses.svg';
import waveRed from '../../images/landing-page-wave-red.svg';
import waveBrown from '../../images/landing-page-wave-brown.svg';
import waveLightRed from '../../images/landing-page-wave-light-red.svg';
import basicWire from '../../images/basic-wire.svg';
import plan from '../../images/planspowiedzi.jpg';

import './Home.scss';
import { MdPadding } from 'react-icons/md';

const Home = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Sidebar />
        <Navbar />

        <h1 className='landing-page-title'>
          <span className='first'>
            Parafia Chrystusa Dobrego Pasterza
          </span>{' '}
          w{' '}
          <span className='second'>
            Białych Błotach
          </span>
        </h1>

        <img
          src={cloudLeft}
          alt='chmura'
          className='landing-page-cloud left'
        />
        <img
          src={cloudRight}
          alt='chmura'
          className='landing-page-cloud right'
        />

        <img
          src={jesus}
          alt='Jezus'
          className='landing-page-jesus'
        />

        <img
          src={bigCrosses}
          alt='krzyże'
          className='landing-page-big-crosses'
        />

        <img
          src={waveLightRed}
          alt='jasno czerwona fala'
          className='landing-page-wave light-red'
        />
        <img
          src={waveRed}
          alt='czerwona fala'
          className='landing-page-wave red'
        />
        <img
          src={waveBrown}
          alt='brazowa fala'
          className='landing-page-wave brown'
        />

        <ScrollButton />
      </main>
      <section className='animation-section'>
        <div className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Ogłoszenia parafialne
            </h2>
            <h3 className='ogloszenia-title'>
              XXVI Niedziela Zwykła
            </h3>
            <h4 className='ogloszenia-date'>
              29 września 2024 r.
            </h4>
            <br />
            <ol
              type='1'
              start='1'
              className='ogloszenia-list'
            >
              <li>
                Z całego serca dziękujemy za
                ofiary składane na tacę tradycyjną
                i elektroniczną. Serdecznie
                dziękujemy za dodatkowe ofiary
                przekazane na cele gospodarczo –
                remontowe od rodzin: z ul. Cynowej
                500 zł, ul. Czerskiej 200 zł, ul.
                Białej 200 zł, ul. Czajcza 200 zł,
                i ul. Chlebowej 100 zł. Dziękujemy
                również rodzinie z Bydgoszczy 200
                zł i z Lubonia 100 zł. Serdeczne
                Bóg zapłać!
              </li>
              <li>
                W tym tygodniu w liturgii
                wspominamy:
                <br />• w poniedziałek – św.
                Hieronima, prezbitera i Doktor
                Kościoła.
                <br />• we wtorek – św. Teresę od
                Dzieciątka Jezus, dziewicę i
                Doktora Kościoła,
                <br />• w środę – świętych Aniołów
                Stróżów,
                <br />• w piątek – św. Franciszka
                z Asyżu, zakonnika,
                <br />• w sobotę – św. Faustyny
                Kowalskiej, dziewicy
              </li>
              <li>
                W tym tygodniu również przypada:
                <br />• I środa miesiąca – o godz.
                17.30 modlitwa różańcowa połączona
                z modlitwą o dobrą śmierć za
                wstawiennictwem św. Józefa
                prowadzona przez wspólnotę Żywego
                Różańca;
                <br />• I czwartek miesiąca –
                różaniec z modlitwą o powołania
                prowadzony przez wspólnotę Żywego
                Różańca, SWP i Daru Komunii o
                godz. 17.30. W tym czasie
                możliwość spowiedzi od 17.30 do
                18.00. Zmiana tajemnic na
                spotkaniu wspólnot o godz. 16.30 w
                salce domu parafialnego
                <br />• I piątek miesiąca –
                nabożeństwo wynagradzające ku czci
                Najświętszego Serca Pana Jezusa
                przed Mszą Świętą. o godz. 8.00 i
                18.00. Okazja do spowiedzi od
                godz. 17.15 do 18.00.
                <br />• I sobota miesiąca – ks.
                Robert odwiedzi chorych 5
                października. Natomiast ks.
                Proboszcz w piątek 11 października
                po powrocie z pielgrzymki;
                <br />• w niedzielę 6 października
                – Msza św. o godz. 10.00 z
                odnowieniem przyrzeczeń
                małżeńskich dla par, które
                ślubowały w miesiącu październiku.
                Po zakończonej Eucharystii kawa i
                ciasto w „Kawiarence Dobrego
                Pasterza”. Mile widziane słodkości
                do kawy
              </li>
              <li>
                Od wtorku rozpoczynamy miesiąc
                październik poświęcony Matce Bożej
                Różańcowej. Pragniemy chwytając za
                różaniec wypraszać łaski dla
                Kościoła, parafii oraz naszych
                rodzin. Nabożeństwa różańcowe w
                naszym kościele codziennie o godz.
                17.30. Wszystkie dzieci zapraszamy
                na różaniec w kościele w
                poniedziałki i czwartki o godz.
                16.30. Serdecznie zapraszamy do
                wspólnej modlitwy. Wiemy jak
                bardzo potrzebujemy dziś modlitwy
                dlatego zapraszamy chętne osoby do
                wstępowania do działających grup
                modlitwy.
              </li>
              <li>
                W poniedziałki po wieczornej Mszy
                Świętej zapraszamy na spotkanie
                modlitewne „Odnowy w Duchu
                Świętym”, a we wtorki po
                wieczornej Mszy Świętej zapraszamy
                chętnych na otwarte spotkanie
                modlitewne prowadzone przez
                wspólnotę „Nowe Przymierze”. W
                czwartki około 18.30 adoracja
                Najświętszego Sakramentu z
                modlitwą do Chrystusa Dobrego
                Pasterza.
              </li>
              <li>
                Zapraszamy na kolejne nocne
                czuwanie pt. „Z Maryją w sercu i
                różańcem w ręku” w intencji
                skruszenia murów zła w
                małżeństwach i rodzinach oraz w
                intencji pokoju na świecie.
                Czuwanie rozpocznie się 5
                października po Mszy św. o godz.
                18.00, a zakończy się w niedzielę
                6 października Mszą Świętą o godz.
                8.00. Prosimy o wpisywanie się na
                konkretne godziny. W tym celu na
                stoliku pod chórem została
                wyłożona lista z propozycjami
                godzin odmawiania różańca.
                Zachowajmy ciągłości modlitwy
                podejmując to piękne, a zarazem
                bardzo trudne zobowiązanie nocnej
                modlitwy różańcowej.
              </li>
              <li>
                W czwartek 10 października o godz.
                19.00 rusza „Parafialna Szkoła
                Biblijna”. Kolejny rok pochylać
                się będziemy nad słowem Bożym.
                Comiesięczne spotkania w II
                czwartki miesiąca poświęcimy w
                dalszym ciągu Ewangelii wg św.
                Mateusza. Wykład wraz z warsztatem
                poprowadzi ks. dr Rafał Muzolf –
                proboszcz parafii Matki Bożej
                Bolesnej w Cielu.
              </li>
              <li>
                Jest dostępna księga intencji na
                przyszły rok 2025. Rezerwować Msze
                Święte w godzinach biura
                parafialnego (wtorek i czwartek od
                16.00 do 17.00) oraz po każdej
                Mszy Świętej. Prosimy przy
                rezerwacji o podawanie swojego
                numeru telefonu co ułatwi nam
                kontakt w przypadku braku treści
                intencji lub zmiany terminu.
                Przypominamy, że w środy o godz.
                18.00 odprawiamy Mszę zbiorową w
                różnych przedstawianych
                intencjach.
              </li>
              <li>
                Przypominamy, że Msza Święta ze
                szczególnym udziałem dzieci
                odprawiamy o godz. 12.00, w każdą
                niedzielę i uroczystości. Dzisiaj
                po Mszy Świętej o godz. 12.00
                zapraszamy rodziców dzieci
                przygotowujących się do I Komunii
                Świętej na spotkanie w kościele.
                Natomiast młodzież klas 7 i 8 oraz
                klas 1 szkół ponadpodstawowych
                przygotowującą się do sakramentu
                bierzmowania zapraszamy również
                dzisiaj na spotkanie wraz z
                rodzicami po Mszy Świętej o godz.
                18.00.
              </li>
              <li>
                W ubiegłą niedzielę podczas
                zbiórki pieniędzy na rzecz
                powodzian zebraliśmy w naszej
                parafii i przekazaliśmy za
                pośrednictwem „Caritas” 6378 zł.
                Oprócz pomocy pieniężnej przez
                pośrednictwo „Caritas” pragniemy
                jako wspólnota parafialna włączyć
                się w akcję „Paczka dla
                powodzian”. Dlatego zapraszamy
                chętnych, którzy mają możliwość i
                ochotę wesprzeć akcję prosimy o
                przynoszenie produktów, które są
                na liście tzw. najbardziej
                potrzebnych. (żywność z długim
                terminem, makarony, kasza,
                konserwy, środki czystości i
                higieny osobistej, baterie,
                rękawiczki itp.) Pełna lista jest
                dostępna na stronie Caritas.
                Artykuły możemy składać pod chórem
                przy stolikach z prasą katolicką
                jeszcze do środy.
              </li>
              <li>
                Drodzy pielgrzymi wyruszający do
                Włoch. Autokar będzie podstawiony
                1 października (we wtorek) na
                godzinę 23.50. Wyruszymy na
                lotnisko w Warszawie około godz.
                24.00. O pół godziny przesunęła
                się godzina wylotu z 7.15 na 7.45.
                Na odprawie musimy być około 5.30.
                Koszt przejazdu do Warszawy i
                powrót do Białych Błot to 240 zł
                od osoby.
              </li>
              <li>
                W niedzielę 13 października
                przeżywać będziemy XXIV Dzień
                Papieski pod hasłem: „Jan Paweł II
                – Ewangelia starości i
                cierpienia”. Również tego dnia
                nastąpi w całym Kościele liczenie
                wiernych. Natomiast 12
                października w sobotę rozpoczniemy
                pierwszy z wykładów w tzw.
                „Akademii Dobrego Pasterza”, który
                będzie dedykowany w sposób
                szczególny rodzicom, których
                dzieci w chodzą w czas
                dojrzewania. Temat pt. „Kolczasty
                nastolatek” przedstawi i
                poprowadzi spotkanie Pan Wiesław
                Gajewski. Szczegóły podamy w
                przyszłą niedzielę.
              </li>
              <li>
                Zachęcamy do czytania prasy
                katolickiej. W Przewodniku
                Katolickim artykuł o tym jak
                mądrze pomagać ludziom dotkniętym
                powodzią. W tym numerze dodatek
                modlitewnik na miesiąc październik
                oraz nowy numer „List do Pani”.
              </li>
              <li>
                W minionym tygodniu odeszły do
                Pana:
                <br />+ Zofia Gregorkiewicz, lat
                76, zam. ul, Drzewna;
                <br />+ Beata Trapuk, lat 56, zam.
                w Bydgoszczy
                <br />
                …wieczny odpoczynek racz im dać
                Panie…
              </li>
            </ol>
            {/* <img
              src={plan}
              className='default-border planspowiedzi'
              alt='Plan Spowiedzi'
            ></img> */}
          </article>
          {/* <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Plan wizyty duszpasterskiej w roku
              2024
            </h2>
            <h4 className='ogloszenia-title koleda-center '>
              Rozpoczynamy w dni powszednie o
              godz. 16.00, w sobote o godz. 11:00.
            </h4>
            <br />
            <br />
            <br /> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>31.01 2023 r.</td>
                  <td>Gronowa, Czwartaków</td>
                  <td>
                    Czarnoleska, Chełmska, Cukrowa
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 2, ul.
                    Berberysowa, ul. Bałkańska,
                    ul. Bagatela
                  </td>
                </tr>
                <tr>
                  <td>3.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 4 i blok
                    nr 6 oraz ul. Bracka, ul.
                    Bartnicza
                  </td>
                </tr>
                <tr>
                  <td>4.01, 16:00</td>
                  <td>
                    ul. Betonowa domki, ul.
                    Altanowa, ul. Alibaby, ul.
                    Szubińska nr 4-12, ul. Biała,
                    ul. Betonowa 1a
                  </td>
                </tr>
                <tr>
                  <td>5.01, 16:00</td>
                  <td>
                    ul. Azalowa, ul. Ametystowa,
                    ul. Asfaltowa, ul. Jaśminowa,
                    ul. Leśna, ul. Konwaliowa, ul.
                    Szafirowa, ul. Tulipanowa, ul.
                    Żonkilowa, ul. Różana
                  </td>
                </tr>
                <tr>
                  <td>8.01, 16:00</td>
                  <td>
                    ul. Chmielna, ul. Chmielarska,
                    ul. Czarnoleska, ul. Chełmska,
                    ul. Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>9.01, 16:00</td>
                  <td>
                    ul. Chlebowa 7-83, ul.
                    Iglasta, ul. Izoldy, ul.
                    Cedrowa, ul. Cyprysowa
                  </td>
                </tr>
                <tr>
                  <td>10.01, 16:00</td>
                  <td>
                    ul. Chlebowa 2-76, ul.
                    Gronowa, ul. Czwartaków
                  </td>
                </tr>
                <tr>
                  <td>11.01, 16:00</td>
                  <td>
                    ul. Chlebowa 85-105, ul.
                    Boruty, ul. Zawiła, ul.
                    Czeremchy, ul. Cynowa, ul.
                    Daliowa
                  </td>
                </tr>
                <tr>
                  <td>12.01, 16:00</td>
                  <td>
                    ul. Czysta, ul. Bajeczna, ul.
                    Czekoladow
                  </td>
                </tr>
                <tr>
                  <td>13.01, 11:00</td>
                  <td>
                    ul. Chudoby, ul. Cukiernicza,
                    ul. Ulana
                  </td>
                </tr>
                <tr>
                  <td>15.01, 16:00</td>
                  <td>ul. Gontowa, ul. Gwarna</td>
                </tr>
                <tr>
                  <td>16.01, 16:00</td>
                  <td>
                    ul. Drzewna, ul. Czajcza, ul.
                    Zeusa
                  </td>
                </tr>
                <tr>
                  <td>17.01, 16:00</td>
                  <td>
                    ul. Duńska, ul. Ezopa, ul.
                    Daleka, ul. Żaków, ul. Cała
                  </td>
                </tr>
                <tr>
                  <td>18.01, 16:00</td>
                  <td>
                    ul. Guliwera, ul. Modra, ul.
                    Letnia, ul. Moczary
                  </td>
                </tr>
                <tr>
                  <td>19.01, 16:00</td>
                  <td>
                    ul. Ostróżki, ul. Szubińska 87
                    E, ul. Przemysłowa, ul.
                    Wierzbowa, ul. Zacisze, ul.
                    Kapliczna, ul. Szubińska
                  </td>
                </tr>
                <tr>
                  <td>20.01, 11:00</td>
                  <td>
                    ul. Centralna 2-66 i 9-41a,
                    ul. Cisowa, ul. Centralna 47 i
                    68 do 110 i 113
                  </td>
                </tr>
                <tr>
                  <td>22.01, 16:00</td>
                  <td>
                    ul. Jaracza, ul. Czerska
                    57-111, ul. Forteczna, ul.
                    Owcza, ul. Feniksa, ul.
                    Jutrzenki 1 i 1a, ul. Judyma
                  </td>
                </tr>
                <tr>
                  <td>23.01, 16:00</td>
                  <td>
                    ul. Czerska 2-36, ul. Czerska
                    1-55
                  </td>
                </tr>
                <tr>
                  <td>24.01, 16:00</td>
                  <td>
                    ul. Czahary od 2-34 i 3-27,
                    ul. Sielska, ul. Czahary(od
                    numerów końcowych), ul.
                    Sobótki, ul. Parkowa
                  </td>
                </tr>
                <tr>
                  <td>25.01, 16:00</td>
                  <td>
                    ul. Literacka, ul. Olchowa
                  </td>
                </tr>
                <tr>
                  <td>26.01, 16:00</td>
                  <td>
                    ul. Hodowlana, ul. Rzepichy,
                    ul. Ludowa
                  </td>
                </tr>
                <tr>
                  <td>27.01, 11:00</td>
                  <td>
                    ul. Hippiczna - nr
                    nieparzyste, ul. Hippiczna –
                    nr parzyste
                  </td>
                </tr>
                <tr>
                  <td>29.01, 16:00</td>
                  <td>
                    ul. Barwinkowa, ul. Epokowa,
                    ul. Barwinkowa 1-29 i 26-28c
                  </td>
                </tr>
                <tr>
                  <td>30.01, 16:00</td>
                  <td>
                    ul. Goplany, ul. Herbowa, ul.
                    Hebanowa, ul. Dworska, ul.
                    Arlekina, ul. Alpejska, ul.
                    Bazaltowa
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;31.01, 16:00&nbsp;
                  </td>
                  <td>
                    ul. Niedzielna, ul. Miła, ul.
                    Lechicka, ul. Młoda, ul.
                    Turkusowa, ul. Piesza, ul.
                    Mokra
                  </td>
                </tr>
                <tr>
                  <td>1.02, 16:00</td>
                  <td>
                    ul. Temidy, ul. Jantarowej 8 i
                    10, ul. Lutników, ul.
                    Ogniskowa, ul. Trawiasta, ul.
                    Nizinna
                  </td>
                </tr>
                <tr>
                  <td>3.02, 11:00</td>
                  <td>
                    ul. Żeńców, ul. Kadetów, ul.
                    Jantarowa, ul. Uroczysko, ul.
                    Barycka
                  </td>
                </tr>
                <tr>
                  <td>5.02, 16:00</td>
                  <td>
                    ul. Popiela, ul. Moczary, ul.
                    Reduty, ul. Jutrzenki
                  </td>
                </tr>
              </tbody>
            </table> */}
          {/* </article> */}
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
