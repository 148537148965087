import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import basicWire from '../../images/basic-wire.svg';

import './Intencje.scss';

const Intencje = () => {
  return (
    <div className='animation-wrapper'>
      <div className=' animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <section className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Intencje Mszalne
            </h1>
            <article className='flexbox-wrapper intencje-flexbox'>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 29.09.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Arch. Michała, Gabriela i
                    Rafała
                  </h4>
                  <br />
                  <b>8:00</b> + Józef i Zofia
                  Kubala, rodzice i rodzeństwo z
                  obojga stron oraz Łucja i Alojzy
                  Rucińscy, Janina i Jan
                  Kaliszewscy
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.28)
                  <br />
                  <b>10:00</b> + Edward Kimpel,
                  zm. rodziców i rodzeństwo
                  <br />
                  <b>10:00</b> + Wiktor Pawlak (w
                  rocz. śmierci) – o dar Nieba
                  <br />
                  <b>12:00</b> + Leon Lech Nowak
                  (10 rocz. śm.) oraz dusze w
                  czyśćcu cierpiące – o łaskę
                  Nieba i światłość wiekuistą –
                  int. od żony i córki z rodzinami
                  <br />
                  <b>12:00</b> + mąż Wiesław Gacka
                  (9 rocz. śm.), zm. rodziców z
                  obojga stron i zięcia Krzysztofa
                  <br />
                  <b>12:00</b> Chrzest
                  <br />
                  <b>18:00</b> + Teresa Rycąbel i
                  zm. z rodziny Czajkowskich –
                  Zygfryd, Cecylia i Kazimierz
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 6.10.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Uroczystość Najśw. Trójcy
                  </h4> */}
                  <br />
                  <b>8:00</b> + Franciszek i
                  Jadwiga Kwiatkowscy oraz ks.
                  Franciszek Regulski
                  <br />
                  <b>8:00</b> + Wacław Kozaryn
                  (greg. 1)
                  <br />
                  <b>10:00</b> Z okazji 60-ych ur.
                  Jerzego z prośbą o Boże błog.,
                  wszelkie łaski i opiekę MB
                  <br />
                  <b>10:00</b> + Sylwester (15
                  rocz. śm.) i Irena Głowaccy oraz
                  Irena i Tadeusz Krzysztofik
                  <br />
                  <b>12:00</b> + rodzice Marianna
                  i Wacław Bujewscy – o dar Nieba
                  <br />
                  <b>12:00</b> + Teresa, Józef i
                  Konrad Jankowiak oraz Maria
                  Ziółkowska
                  <br />
                  <b>18:00</b> REZERWACJA
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Poniedziałek 30.09.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    św. Hieronima
                  </h4>
                  <br />
                  <b>8:00</b> + Jadwiga Kozaryn
                  (greg.29)
                  <br />
                  <b>18:00</b> + Tomasz Pikul –
                  int. od kuzynki z Chicago
                  <br />
                  <b>18:00</b> + Urszula
                  Czapiewska – int. od Wioletty i
                  Jarosława Kupiec
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Wtorek 1.10.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Święto Przemienienia Pańskiego
                  </h4> */}
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> + Katarzyna
                  Samotyja (5 rocz. śm.)
                  <br />
                  <b>18:00</b> + Jadwiga Kozaryn
                  (greg.30, zakończenie)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Środa 2.10.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    Św. Aniołów Stróżów
                  </h4>
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> W intencji Nowenny
                  do MB Nieust. Pomocy:
                  <br />- Dziękczynna za otrzymane
                  łaski, z prośbą o Boże błog.,
                  opiekę MB dla Renaty i Czesława
                  Jaszkowskich z okazji 46 rocz.
                  ślubu i o siły w walce z chorobą
                  dla Renaty
                  <br />- o zapał ewangelizacyjny
                  dla pracowników mediów
                  katolickich
                  <br />+ Jan (36 rocz. śm.),
                  Teresa i Józef Marchel; Marta,
                  Jan, Kazimierz Cieślik oraz o
                  zdrowie dla córki Beaty i o
                  błog. Boże dla Leny w dniu 15
                  rocz. ur.
                  <br />+ Danuta Siewert (Ziwert)
                  (2 rocz. śm.)
                  <br />+ Zofia Gregorkiewicz –
                  int. od Zakładu Pogrzebowego z
                  Białych Błot
                  <br />+ Beata Trapuk - – int. od
                  Zakładu Pogrzebowego z Białych
                  Błot
                  <br />+ Ryszard Bieniaszewski –
                  int. od uczestników pogrzebu
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Czwartek 3.10.2024 r.
                  </h3>
                  {/* <h4 className='intecja-undertitle'>
                    Zakończenie oktawy Bożego
                    Ciała
                  </h4> */}
                  <br />
                  <b>8:00</b> + Stefan i Marianna
                  Szajerscy oraz Aniela i Tadeusz
                  Szafrańscy
                  <br />
                  <b>18:00</b> W int. Teresy z
                  okazji urodz. i im. – o Boże
                  błog, zdrowie i opiekę MB dla
                  Solenizantki i całej rodziny
                  <br />
                  <b>18:00</b> + Kazimierz (15
                  rocz. śm.), Franciszek,
                  Franciszka(k) i Weronika
                  Kowalscy
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Piątek 4.10.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    św. Franciszka z Asyżu
                  </h4>
                  <br />
                  <b>8:00</b> Wolna intencja
                  <br />
                  <b>18:00</b> + Jan (43 rocz.
                  śm.) i Aniela Kornaccy oraz
                  rodzice i rodzeństwo z obojga
                  stron
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Sobota 5.10.2024 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    św. Faustyny Kowalskiej
                  </h4>
                  <br />
                  <b>8:00</b> W intencji Tomasza z
                  okazji 8-ych ur. z prośbą o Boże
                  błog., Dary Ducha Św. i opiekę
                  MB
                  <br />
                  <b>18:00</b> + Marianna
                  Bednarska (35 rocz. śm.)
                  <br />
                  <b>18:00</b> + Renata i Henryk
                  Pluta
                </div>
              </div>
            </article>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Intencje;
